nav {
    display: flex;
    justify-content: space-between; /* Distribute items evenly along the main axis */
    align-items: center; /* Center items vertically */
    width: 100%;
    height: 12%;
    padding: 1% 0 2%;
    outline: 5px solid hsla(219deg, 78%, 15%, 0.6);
    margin-bottom: 40px;
}

nav h1 {
    width: 40%;
    text-align: center;
    color: #f5f5f5;
    text-shadow:
         0px -1px 8px hsl(36deg 100% 55%),
         0px -2px 12px hsl(20deg 100% 50%);
}

nav h2 {
    width: 30%;
    text-align: center;
    padding-top: 2%;
    color: #f5f5f5;
    text-shadow:
        0px -1px 8px hsl(36deg 100% 55%),
        0px -2px 12px hsl(20deg 100% 50%);
}

nav h1:hover,
nav h2:hover {
    cursor: pointer;
    animation: flicker 1.5s infinite;
}

@keyframes flicker {
    /* Start of animation */
    0%,
    /* Final state of animation */
    100% {
        text-shadow:
            0px -1px 3px hsl(47deg 100% 94%),
            0px -2px 6px hsl(52deg 100% 75%),
            0px -6px 12px hsl(36deg 100% 55%),
            0px -10px 20px hsl(20deg 100% 50%);
    }

    50% {
        text-shadow:
            0 -2px 6px hsl(47deg 100% 94%), /* Innermost layer - intense heat (white) 6 */
            0 -4px 12px hsl(52deg 100% 75%), /* Second layer - core of flame (yellow) 12 */
            0 -8px 16px hsl(36deg 100% 55%), /* Middle layer - body of flame (orange) 16 */
            0 -12px 24px hsl(20deg 100% 50%); /* Outermost layer - edges of flame (red) 24 */
    }
}