#overview h3 {
    padding: 0 40px;
}

#overview article {
    width: 100%;
    margin-bottom: 30px;
    padding-bottom: 40px;
}

#overview img {
    width: 200px;
    height: 200px;
}

#overview p {
    margin-top: 8px;
    padding: 5px 5px 0 5px;
    display: block;
    text-align: justify;
    font-size: 18px;
}

#overview .floatLeft img{
    float: right;
    margin: 0 0 10px 15px;
}

#overview .floatLeft h3 {
    text-align: left;
}

.floatRight img {
    float: left;
    margin: 0 15px 10px 0;
}

.floatRight h3 {
    text-align: right;
}

