* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  min-height: 100vh;
  color: lightgrey;
  font-family: Arial, sans-serif;
  background: rgb(11,8,47);
  background: radial-gradient(circle, hsl(219deg 78% 5%) 10%,
    hsl(219deg 78% 10%) 30%,
    hsl(219deg 78% 15%) 50%,
    hsl(219deg 78% 15%) 60%,
    hsl(219deg 78% 10%) 85%,
    hsl(219deg 78% 5%) 90%);
}

#root {
  height: 90vh;
  width: 80%;
  border-radius: 50px;
  background-color: hsla(0deg, 0%, 0%, 0.4);
  border: 10px solid hsla(0deg, 0%, 0%, 0);
  overflow: hidden;
}

h1 {
  font-size: 45px;
}

h2 {
  font-size: 25px;
}

h3 {
    font-size: 30px;
}

h4 {
    font-size: 20px;
}

table {
    margin: 30px auto 80px;
    border-spacing: 10px;
}

th {
    background-color: hsla(0deg, 100%, 100%, 0.35);
    font-size: 18px;
    padding: 10px;
    border-radius: 8px;
}

td {
    background-color: hsla(0deg, 100%, 100%, 0.2);
    font-size: 15px;
    padding: 10px;
    text-align: center;
    border-radius: 8px;
}


