.languages {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 10px 10px 0 0;
    padding: 5px;
    background-color: hsla(0deg, 100%, 100%, 0.3);
}

.languageFlag {
    margin: auto;
    height: 30px;
    width: 40px;
}

.languageFlag:hover {
    cursor: pointer;
}
